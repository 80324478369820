<template>
  <div class="eyeDiagnosis" @click="icdShow=false">
      <h3>眼部诊断</h3>
      <ul class="nr">
          <li><h4>检查结果</h4></li>
          <li v-if="postData.S_OP_Json.CheckResult_yanweiByEyeCheck">眼位</li>
          <li v-if="postData.S_OP_Json.CheckResult_CampaignOD">眼球运动</li>
          <li v-if="postData.S_OP_Json.CheckResult_IPOD">眼压</li>
          <li v-if="postData.S_OP_Json.CheckResult_OcularAxisOD">眼轴</li>
          <li v-if="postData.S_OP_Json.CheckResult_SLOD">基础检查</li>
      </ul>
      <ul class="nr ys">
          <li>右眼</li>
          <li v-if="postData.S_OP_Json.CheckResult_yanweiByEyeCheck">{{postData.S_OP_Json.CheckResult_yanweiByEyeCheck}}</li>
          <li v-if="postData.S_OP_Json.CheckResult_CampaignOD">{{postData.S_OP_Json.CheckResult_CampaignOD}}</li>
          <li v-if="postData.S_OP_Json.CheckResult_IPOD">{{postData.S_OP_Json.CheckResult_IPOD}}mmHg</li>
          <li v-if="postData.S_OP_Json.CheckResult_OcularAxisOD">{{postData.S_OP_Json.CheckResult_OcularAxisOD}}mm</li>
          <li v-if="postData.S_OP_Json.CheckResult_SLOD">{{postData.S_OP_Json.CheckResult_SLOD}}</li>
      </ul>
      <ul class="nr ys">
          <li>左眼</li>
          <li v-if="postData.S_OP_Json.CheckResult_yanweiByEyeCheck">{{postData.S_OP_Json.CheckResult_yanweiByEyeCheck}}</li>
          <li v-if="postData.S_OP_Json.CheckResult_CampaignOD">{{postData.S_OP_Json.CheckResult_CampaignOS}}</li>
          <li v-if="postData.S_OP_Json.CheckResult_IPOD">{{postData.S_OP_Json.CheckResult_IPOS}}mmHg</li>
          <li v-if="postData.S_OP_Json.CheckResult_OcularAxisOD">{{postData.S_OP_Json.CheckResult_OcularAxisOS}}mm</li>
          <li v-if="postData.S_OP_Json.CheckResult_SLOD">{{postData.S_OP_Json.CheckResult_SLOS}}</li>
      </ul>

      <div class="box">
        <h4>诊断结论</h4>
        <div class="xz" style="height: 155px;">
              <el-checkbox-group v-model="diagnosis" class="dx">
                  <el-checkbox class="xxbos" :label="0">正常</el-checkbox>
                  <el-checkbox class="xxbos" :label="1">眼干</el-checkbox>
                  <el-checkbox class="xxbos" :label="2">白内障</el-checkbox>
                  <el-checkbox class="xxbos" :label="3">青光眼待查</el-checkbox>
                  <el-checkbox class="xxbos" :label="4">翼状胬肉</el-checkbox>
                  <el-checkbox class="xxbos" :label="5">角膜上皮缺损</el-checkbox>
                  <el-checkbox class="xxbos" :label="6">屈光不正</el-checkbox>
                  <el-checkbox class="xxbos" :label="7">眼底病变</el-checkbox>
                  <el-checkbox class="xxbos" :label="8">泪道堵塞</el-checkbox>
                  <el-checkbox class="xxbos" :label="9">斜视</el-checkbox>
                  <el-input
                      class="bnt"
                      placeholder="自定义"
                      v-model="postData.S_OP_Json.CheckResult_CustomConclusion"
                      clearable>
                  </el-input>
              </el-checkbox-group>
          </div>
        <div class="icd">
          <div class="sou">
            <p>ICD编码:</p>
            <el-input class="srk" v-model="ICDCODE" placeholder="请输入内容"></el-input>
            <el-popover
                placement="bottom"
                width="300"
                trigger="manual"
                v-model="icdShow">
              <ul class="icdlistss">
                <li v-for="item in icdgetList" @click="addicd(item)">{{item.ICDCode}}:{{item.ICDName}}</li>
              </ul>
              <i slot="reference" @click="geticd" class="el-icon-search"></i>
            </el-popover>
          </div>
          <ul class="icd-nr">
            <li v-for="(item, idx) in icdlist">
              <el-tooltip placement="top" effect="light">
                <div slot="content"><el-button size="small" @click="deleicd(idx)" type="danger">删除</el-button></div>
                <p class="icdnrs">{{idx+1}}. {{item}}</p>
              </el-tooltip>
            </li>
          </ul>
        </div>
      </div>


      <div  class="box" style="padding-left: 1.95rem;">
        <el-button type="primary" size="small" @click="tz(1, 'uf_baseoptometry')">裂隙灯</el-button>
        <el-button type="primary" size="small" @click="tz(2, 'uf_fundusoptometry')">眼底检查</el-button>
      </div>

      <div class="box">
          <h4>医嘱</h4>
          <div class="xz yz">
              <el-input
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 4}"
                      placeholder="请输入内容"
                      v-model="postData.S_OP_Json.CheckResult_Comment">
              </el-input>
          </div>
      </div>

      <div class="hx" style="height: 5px"></div>

      <div class="box jycl">
          <h4>建议处理</h4>
          <div class="xz h2" style="width: 12rem; min-height: 13vh !important;text-align: left;">
              <el-checkbox-group v-model="checkList" class="dx">
                  <el-checkbox class="xxbos" :label="0">验光配镜</el-checkbox>
                  <el-checkbox class="xxbos" :label="1">进一步检查</el-checkbox>
                  <el-checkbox class="xxbos" :label="2">定期复诊</el-checkbox>
                  <el-checkbox class="xxbos" :label="3">转诊</el-checkbox>
                  <el-checkbox class="xxbos" :label="5">药物治疗</el-checkbox>
                  <el-checkbox class="xxbos" :label="4">门诊治疗</el-checkbox>
                  <el-select
                    v-if="isshowmzzl && 0"
                    class="srr"
                    v-model="postData.S_OP_Json.CheckResult_DealSuggestion_Des"
                    @focus="xzIdx = 1"
                    filterable
                    allow-create
                    collapse-tags
                    default-first-option
                    placeholder="请选择或输入">
                    <el-option
                      v-for="item in zlsf"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                  <el-button class="bnt"  @click="go('exam')" type="info" size="medium">治疗收费</el-button>
              </el-checkbox-group>
            <div class="ywlist" v-if="isywzl">
              <div class="ywlist-title">
                <div>
                  <p>药物治疗</p>
                  <el-button size="small" @click="add">添加</el-button>
                </div>
<!--                <el-button size="small" @click="go">选择药品</el-button>-->
                <ul>
                  <li>药物名称</li>
                  <li v-for="(item, idx) in ywzlData">
                    <el-select
                        @change="bh($event, idx)"
                        size="small"
                        class="srr"
                        v-model="item.id"
                        @focus="xzIdx = 1"
                        filterable
                        allow-create
                        collapse-tags
                        default-first-option
                        placeholder="请选择或输入">
                      <el-option
                          v-for="item in yplist"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                      </el-option>
                    </el-select>
                    <el-button size="small" @click="yyxq(item, idx)">用药详情</el-button>
                    <i class="el-icon-circle-close" @click="deletes(item.ChooseProductId, idx)" style="font-size: 25px; margin-left: 20px;cursor: pointer;"></i>
                  </li>
                </ul>
              </div>
            </div>
            <el-button style="margin-top: 10px;" size="small" @click="$router.push({path: '/ifame2', query:{type: 1}})">药品处方</el-button>
          </div>
      </div>

      <el-button type="primary" @click="saves">保存</el-button>
      <el-button type="primary" @click="ysqm">医生签名</el-button>
      <el-dialog
        title="用药详情"
        :visible.sync="yyxqshow"
        width="60%">
      <ul class="yyxq">
        <li>
          <p>用药周期</p>
          <el-select
              size="small"
              class="srr"
              filterable
              allow-create
              v-model="ypitem.Drug_Days"
          >
            <el-option
                v-for="item in yyts"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </li>
        <li>
          <p>用药方式</p>
          <el-select
              size="small"
              class="srr"
              filterable
              allow-create
              v-model="ypitem.Drug_UseMethod"
          >
            <el-option
                v-for="item in yyfs"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </li>
      </ul>
      <ul class="yyxq">
        <li>
          <p>用药频次</p>
          <el-select
              size="small"
              class="srr"
              filterable
              allow-create
              v-model="ypitem.Drug_CountPerDay"
          >
            <el-option
                v-for="item in cishu"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </li>
        <li>
          <p>用药规律</p>
          <el-select
              size="small"
              class="srr"
              filterable
              allow-create
              v-model="ypitem.Drug_TimeInDay"
          >
            <el-option
                v-for="item in shijian"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </li>
      </ul>
      <ul class="yyxq">
        <li>
          <p>眼别</p>
          <el-select
              size="small"
              class="srr"
              filterable
              allow-create
              v-model="ypitem.LeftOrRight"
          >
            <el-option
                v-for="item in yyyanbie"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </li>
        <li>
          <p>每次用量</p>
          <el-select
              size="small"
              class="srr"
              filterable
              allow-create
              v-model="ypitem.Drug_CountPerTime"
          >
            <el-option
                v-for="item in yyyl"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </li>
      </ul>


      <el-button type="primary" style="margin-top: 20px;" @click.stop="desqd" size="small">确定</el-button>
    </el-dialog>

      <el-dialog
        title="提示"
        :visible.sync="showmsgTxt"
        :close-on-press-escape="false"
        :close-on-click-modal='false'
        :show-close='false'
        width="400px">

        <p style="padding-bottom: 20px;">{{msgText}}</p>
      <el-button type="primary" @click="gocz">确定</el-button>
    </el-dialog>
  </div>
</template>

<script>
    import intraocularTension from "../specialSurvey/intraocularTension";
    import pf from "../../publicFn/baseFn"
    export default {
      name: "eyeDiagnosis",
      data() {
            return {
              msgText: '',
              showmsgTxt: false,
              showzc: false,
              czjlurl: '',
              checkList: [],
              diagnosis: [],
              textarea2: '',
              input: '',
              postData: {S_OP_Json: {}},
              zlsf: [
                {
                  value: '倒睫拔除',
                  label: '倒睫拔除'
                },
                {
                  value: '泪道冲洗',
                  label: '泪道冲洗'
                },
                {
                  value: '泪道结膜囊',
                  label: '泪道结膜囊'
                },
                {
                  value: '角膜异物剔除',
                  label: '角膜异物剔除'
                },
                {
                  value: '取结膜结石',
                  label: '取结膜结石'
                },
                {
                  value: '眼睑下垂',
                  label: '眼睑下垂'
                },
                {
                  value: ' ',
                  label: ' '
                }
              ],
              zlsfval: '',
              isshowmzzl: false,
              isywzl: false,
              ICDCODE: '',
              icdShow: false,
              icdgetList: [],
              icdlist: [],
              cs: [
                {
                  value: '1次/2小时',
                  label: '1次/2小时'
                },
                {
                  value: '1次/天',
                  label: '1次/天'
                },
                {
                  value: '2次/天',
                  label: '2次/天'
                },
                {
                  value: '3次/天',
                  label: '3次/天'
                },
                {
                  value: '4次/天',
                  label: '4次/天'
                },
                {
                  value: ' ',
                  label: ' '
                }
              ],
              sysj: [
                {
                  value: '睡前',
                  label: '睡前'
                },
                {
                  value: '日间',
                  label: '日间'
                },
                {
                  value: ' ',
                  label: ' '
                }
              ],

              ywzlData: [],
              ywzlgwc: [],
              yplist: [],
              ypalllist: [],
              yyxqshow: false,
              ypdqidx: -1,

              cishu: [
                {
                  value: '1次/2小时',
                  label: '1次/2小时'
                },
                {
                  value: '1次/天',
                  label: '1次/天'
                },
                {
                  value: '2次/天',
                  label: '2次/天'
                },
                {
                  value: '3次/天',
                  label: '3次/天'
                },
                {
                  value: '4次/2小时',
                  label: '4次/2小时'
                },
                {
                  value: '',
                  label: ''
                },
              ],
              shijian: [
                {
                  value: '睡前',
                  label: '睡前'
                },
                {
                  value: '日间',
                  label: '日间'
                },
                {
                  value: '',
                  label: ''
                },
              ],
              yyts: [
                {
                  value: '1天',
                  label: '1天'
                },
                {
                  value: '2天',
                  label: '2天'
                },
                {
                  value: '3天',
                  label: '3天'
                },
                {
                  value: '4天',
                  label: '4天'
                },
                {
                  value: '5天',
                  label: '5天'
                },
                {
                  value: '6天',
                  label: '6天'
                },
                {
                  value: '7天',
                  label: '7天'
                },
                {
                  value: '10天',
                  label: '10天'
                },
                {
                  value: '2周',
                  label: '2周'
                },
                {
                  value: '3周',
                  label: '3周'
                },
                {
                  value: '1个月',
                  label: '1个月'
                },
                {
                  value: '3个月',
                  label: '3个月'
                },
                {
                  value: '半年',
                  label: '半年'
                },
                {
                  value: '长期',
                  label: '长期'
                },
                {
                  value: '',
                  label: ''
                }
              ],
              yyfs: [
                {
                  value: '口服',
                  label: '口服'
                },
                {
                  value: '外用冷敷',
                  label: '外用冷敷'
                },
                {
                  value: '外用热敷',
                  label: '外用热敷'
                },
                {
                  value: '滴眼',
                  label: '滴眼'
                },
                {
                  value: '涂抹',
                  label: '涂抹'
                },
                {
                  value: '',
                  label: ''
                }
              ],
              cishuVal: '',
              shijianVal: '',
              yyyl: [
                {
                  value: '一次1滴/片/粒',
                  label: '一次1滴/片/粒'
                },
                {
                  value: '一次2滴/片/粒',
                  label: '一次2滴/片/粒'
                },
                {
                  value: '一次3滴/片/粒',
                  label: '一次3滴/片/粒'
                },
                {
                  value: '',
                  label: ''
                }
              ],
              yyyanbie: [
                {
                  value: 'OU',
                  label: 'OU'
                },
                {
                  value: 'OS',
                  label: 'OS'
                },
                {
                  value: 'OD',
                  label: 'OD'
                },
                {
                  value: '',
                  label: ''
                }
              ],
              ypitem: {}
            }
      },
      watch: {
        postData: {
          handler(n, o) {
            this.$store.commit('expertClinic/upExpertClinicData', n)
          },
          deep: true
        },
        checkList: {
          handler(n) {
            let arr = Array(6).fill(0)
            this.isshowmzzl = Array.from(n).some(item => item == 4)
            this.isywzl = Array.from(n).some(item => item == 5)
            for (let i = 0; i < n.length; i++) {
              arr[n[i]] = 1
            }
            this.postData.S_OP_Json.CheckResult_DealSuggestion = arr.join(',')
          },
          deep: true
        },
        diagnosis: {
          handler(n) {
            let arr = [0,0,0,0,0,0,0,0,0,0]
            for (let i = 0; i < n.length; i++) {
              arr[n[i]] = 1
            }
             this.postData.S_OP_Json.CheckResult_Diagnosis = arr.join(',')
          },
          deep: true
        },
        icdlist: {
          handler(n) {
            if (n) {
              this.postData.S_OP_Json.ICD_EyeCheck = this.icdlist.join(',')
            }
          },
          deep: true
        },
      },
      created() {
        if (!this.$store.state.users.employees) {
          return false
        }
        if (!this.$store.state.users.CsUser) {
          return this.$alert('用户没有登录！请登录', '提示')
              .then(res => {
                this.$router.push({path: '/home/acceptsHome'})
              })
        }

        if (!this.$store.state.physicianVisits.xzConsulting) {
          return this.$alert('请选择或者新建咨询', '提示')
              .then(res => {
                this.$router.push({path: '/home/acceptsHome'})
              })
        }

        this.getyplb()

        let log = this.$loading({
          text: '加载中...'
        })

        this._api.expertClinic.getExpertClinic()
        .then(res => {
          log.close()
          if (res.msg !== 'Success') {
            this.$store.commit('expertClinic/upIsSave', res.msg)
            this.showmsgTxt = true
            this.msgText = res.msg
            return
          }else {
            this.$store.commit('expertClinic/upIsSave', 'Success')
          }
          if (res.GetResult) {
            res.GetResult.S_OP_Json = this._clJson(res.GetResult.S_OP_Json)
            this.postData = res.GetResult
            let str = this.postData.S_OP_Json.ICD_EyeCheck
            this.icdlist = str?str.split(','):[]
            this.$store.commit('expertClinic/upisupData', Math.random().toFixed(6).slice(-6))
            this.diagnosis = this.clStr(this.postData.S_OP_Json.CheckResult_Diagnosis)
            //console.log(this.postData.S_OP_Json.CheckResult_DealSuggestion)
            this.checkList = this.clStr(this.postData.S_OP_Json.CheckResult_DealSuggestion)
          }
        })
      },
      methods: {
        clStr(str, num=10) {
            if (!str) return []
            let arr = [], item = str.split(',')
            for (let i = 0; i < item.length; i++) {
              if (Number(item[i])) {
                arr.push(i)
              }
            }
            return arr
        },
        go(type) {
          if (!this.$store.state.users.employees) {
            return this.$alert('员工未登陆，请先登陆','提示')
                .then(res => {
                  this.$router.push({path: '/employeesLanding'})
                })
          }

          if (type=='exam') {
            this.$router.push({
              path: '/frameWithHome/frameChoose?type=exam'
            })
            return
          }

          // this.$router.push({
          //   path: '/frameWithHome/frameChoose?type=drug'
          // })

          let arr = this.$store.state.users.user.Company.ListCompanyConfig
          let yg =  this.$store.state.users.employees,
              csID = this.$store.state.users.CsUser.CustomerId,
              chosID = this.$store.state.physicianVisits.xzConsulting,
              uid = this.$store.state.users.user.UserId,
              shopID = this.$store.state.bases.shop.ShopId,
              ERP_com = ''
          ERP_com = arr.filter(item => item.BCKey == 'ERPSource')
          if (ERP_com && ERP_com.length > 0) {
            ERP_com = ERP_com[0].BCValue
          }else {
            return  this.$router.push({
              path: '/frameWithHome/frameChoose?type=drug'
            })
          }
          if (arr && arr.length > 0) {
            let obj = arr.filter(item => item.BCKey == 'ERP_SellURL')
            let url = obj[0].BCValue
            if (!yg.ListERPCode) {
              return this.$alert('您暂无权限！')
            }
            let yscode = yg.ListERPCode[0].ObjectCode?yg.ListERPCode[0].ObjectCode:''
            if(url) {
              url = url.replace('{ERP_com}', ERP_com)
              url =url.replace('{Expert_Code}', yscode)
              url =url.replace('{CustomerId}', csID)
              url =url.replace('{ChooseId}', chosID)
              url =url.replace('{COAddUserId}', uid)
              url =url.replace('{COExpertId}', yg.ExpertId)
              url = url.replace('{COShopId}', shopID)
            }
            window.open(url)
          }
        },
        geticd() {
          if (!this.ICDCODE) {
            return this.$alert('请输入内容', '提示')
          }
          let lig = this.$loading({
            text: '加载中....'
          })
          this._api.expertClinic.getICDCODE(this.ICDCODE)
          .then(res => {
            lig.close()
            //console.log(res.GetListResult)
            this.icdShow = true
            if (res.GetListResult && res.GetListResult.length) {
               this.icdgetList = JSON.parse(JSON.stringify(res.GetListResult))
            }else {
              this.icdgetList = []
            }
          })
        },
        deleicd(idx) {
          this.icdlist.splice(idx, 1)
        },
        addicd(item) {
          let str = item.ICDCode +":"+item.ICDName
          if (!this.icdlist.some(item => item==str)) {
             this.icdlist.push(str)
          }
          this.icdShow = false
        },
        tz(idx, str) {
          this.$router.push({
            path: '/home/examinationEyesHome?idx=' + idx + '&str='+ str
          })
        },


        getyplb () {
          let obj = {
            "SearchCondition" : {
                  "CHPProductSortGroup" : "drug",
                  "CompanyId" : this.$store.state.users.user.Company.CompanyId,
                  "ChooseId" : this.$store.state.physicianVisits.xzConsulting,
                  "bDelete" : 0,
                  "IndexMin" : 1,
                  "IndexMax" : 20,
                  "CHPStatus" : "1"
            },
            "ProductSource" : "",
            "ReturnInfo" : {
                  "ProductRelation" :
                      {
                        "ShopProduct" : 1,
                        "Product" :
                            {
                              "Brand" : 1,
                              "Sort" : 1
                            }
                      },
                  "InspectionOrder" : 1
            },
            "token" : {
              "sToken" : "AC01E3D93B3F108B690095558CEAE3EC",
              "TimeSpan" : "1688113906"
            }
          }
          this._http.post(this._getUrl('getChooseList'), obj)
          .then(res => {
            // console.log(res)
            let item = []
            if (res.GetListResult && res.GetListResult.length) {
              this.ywzlgwc = [...res.GetListResult]
              for (let i = 0; i < res.GetListResult.length; i++) {
                item.push({
                  id: res.GetListResult[i].CHPProductId,
                  name:  res.GetListResult[i].ProductRelation.ProductDetail.ProductName,
                  des: pf.decoding(res.GetListResult[i].CHPPrescriptionData),
                  ChooseProductId: res.GetListResult[i].ChooseProductId,
                  CHPPrice: res.GetListResult[i].ProductRelation.ShopProduct.SPShopPrice
                })
              }
            }
            this.ywzlData = [...item]

            // console.log(this.ywzlData)
          })


          this._http.post(this._getUrl('priceSortList'), {
            "SearchCondition" : {
              "ShopId" : this.$store.state.bases.shop.ShopId,
              "PriceEnd" : 0,
              "PSId" : "",
              "IndexMin" : 0,
              "BrandId" : "",
              "IndexMax" : 100,
              "ShopBrandOrder" : 1,
              "PriceStart" : 0,
              "SortKey" : "drug"
            },
            "ReturnInfo" : {
              "ShopProduct" : 1,
              "Product" : {
                "Brand" : 0,
                "Sort" : 0
              }  },
            "token" : {
              "sToken" : "8C29167B0ADF034F582C73D350369D8E",
              "TimeSpan" : "1688355555"
            },
            "SearchString" : ""
          })
              .then(res => {
                // console.log(res)
                let item = []
                if (res.GetRelationListResult && res.GetRelationListResult.length) {
                  this.ypalllist = [...res.GetRelationListResult]
                  for (let i = 0; i < res.GetRelationListResult.length; i++) {
                    item.push({
                      value:  res.GetRelationListResult[i].ProductDetail.AllProduct.ProductId,
                      label: res.GetRelationListResult[i].ProductDetail.AllProduct.ProductName
                    })
                  }
                }
                this.yplist = [...item]
              })
        },
        desqd() {
          this.ywzlData[this.ypdqidx].des =  JSON.parse(JSON.stringify(this.ypitem))
          this.yyxqshow = false
        },
        yyxq(obj, idx) {
          this.yyxqshow = true
          this.ypitem = {
            Drug_Days: '',
            Drug_UseMethod: '',
            Drug_CountPerDay: '',
            Drug_TimeInDay: '',
            LeftOrRight: '',
            Drug_CountPerTime: '',
          }

          this.ypdqidx = idx
          // console.log(obj)
          if (obj.des) {
           this.ypitem = JSON.parse(JSON.stringify(obj.des))
          }
        },
        bh(e,idx) {
          // console.log(e)
          if (this.ywzlgwc.length) {
            let gwcidx = -1
            for (let i = 0; i < this.ywzlgwc.length; i++) {
              if (e === this.ywzlgwc[i].CHPProductId) {
                gwcidx = i
              }
            }
            if (gwcidx > -1) {
              this.ywzlData[idx].des = pf.decoding(this.ywzlgwc[gwcidx].CHPPrescriptionData)
              this.ywzlData[idx].name = this.ywzlgwc[gwcidx].ProductRelation.ProductDetail.ProductName
              this.ywzlData[idx].ChooseProductId = this.ywzlgwc[gwcidx].ChooseProductId
            }else {
              this.ywzlData[idx].ChooseProductId = ''
            }
          }
          if (!this.ywzlData[idx].ChooseProductId) {
            this.ywzlData[idx].des = ''
            this.ywzlData[idx].name = ''
            this.ywzlData[idx].ChooseProductId = "00000000-0000-0000-0000-000000000000"
          }
        },
        add() {
          this.ywzlData.push({
            des: '',
            name: '',
            ChooseProductId: '00000000-0000-0000-0000-000000000000',
            id: '',
            CHPPrice: ''
          })
        },

        saveywbc() {
          if (!this.ywzlData.length) return;
          let obj = {
            "token":{
              "sToken":"7BA5E18877AE752DF55FC8874D0BF364",
              "TimeSpan":"1688356076"
            },
            lo: []
          }, arr = []
          for (let i = 0; i < this.ywzlData.length; i++) {
            // console.log(this.ywzlData[i])
            if (this.ywzlData[i].id) {
              let item = {
                ChooseProductId: this.ywzlData[i].ChooseProductId?this.ywzlData[i].ChooseProductId:'00000000-0000-0000-0000-000000000000',
                CHPProductSortGroup: 'drug',
                CHPChooseId: this.$store.state.physicianVisits.xzConsulting,
                CHPPrescriptionData: pf.conversionJson(this.ywzlData[i].des),
                CHPS_OP_ALL_Id: '00000000-0000-0000-0000-000000000000',
                CHPAmount: 1,
                CHPProductId: this.ywzlData[i].id,
                CHPPrice: '',
                CHPRequirements: '',
                CHPProductSource: '',
                CHPExpertId: this.$store.state.users.employees?this.$store.state.users.employees.ExpertId:'00000000-0000-0000-0000-000000000000',
              }
              for (let j = 0; j < this.ypalllist.length; j++) {
                if (this.ywzlData[i].id === this.ypalllist[j].ProductDetail.AllProduct.ProductId) {
                  item.CHPPrice =  this.ypalllist[j].ShopProduct.SPShopPrice
                }
              }
              arr.push(item)
            }
          }
          obj.lo = arr
          this._http.post(this._getUrl('updateChooseList'), obj)
          .then(res => {
            // console.log(res)
          })
        },
        deletes(id, idx) {
          // console.log(id)
          if (id === '00000000-0000-0000-0000-000000000000' || !id) {
            this.ywzlData.splice(idx, 1)
            return
          }
          this._http.post(this._getUrl('deleteProductFromList'), {
            "token" : {    "sToken" : "B2671FDA9B7F5526FD0E64E8561BFC69",    "TimeSpan" : "1688355668"  },  "ChooseProductId" : id
          })
              .then(res => {
                // console.log(res)
                if (res.msg == 'Success') {
                  this.ywzlData.splice(idx, 1)
                }else {
                  this.$alert('删除失败', '提示')
                }
              })
        },

        gocz() {
          this.$router.push({path: '/ifame2', query: {type: 0}})
        },

        ysqm() {
          this._api.expertClinic.ysqm()
              .then(res => {
                if (typeof res === 'object') {
                  if (res.InsertResult) {
                    this.$store.commit('physicianVisits/upIsqm', false)
                    this.$alert('提交签名成功', '提示')
                  }else {
                    this.$alert('提交签名失败', '提示')
                  }
                }
              })
        },
        saves() {
          if (this.$store.state.expertClinic.isSave !== 'Success') {
            return this.$alert(this.$store.state.expertClinic.isSave, '提示')
                .then(res => {
                  this.$router.push({path: '/ifame2', query: {type: 0}})
                })
          }

          let arr = []
          let datas = this.$store.state.expertClinic.expertClinicData.S_OP_Json
          arr[0] = {
            key: 'CheckResult_Diagnosis',
            value: datas.CheckResult_Diagnosis
          }
          arr[1] = {
            key: 'CheckResult_DealSuggestion',
            value: datas.CheckResult_DealSuggestion
          }
          arr[2] = {
            key: 'CheckResult_Comment',
            value: datas.CheckResult_Comment
          }
          arr[3] = {
            key: 'CheckResult_CustomConclusion',
            value: datas.CheckResult_CustomConclusion
          }
          arr[4] = {
            key: 'CheckResult_DealSuggestion_Des',
            value: datas.CheckResult_DealSuggestion_Des
          }
          arr[5] = {
            key: 'ICD_EyeCheck',
            value: datas.ICD_EyeCheck
          }

          this.$store.commit('isbc/upZjzs', {key: 'Recomm1', val: true})

          this.saveywbc()

          this._api.expertClinic.saveExpertClinic(arr)
        }
      }
    }
</script>

<style scoped lang="scss">
   .eyeDiagnosis {
       width: 90%;
       margin: 0 auto;
   }
   h3 {padding-top: .4rem}
   .nr {
       display: flex;
       justify-content: start;
       align-items: center;
       li {
           width: 1.5rem;
           text-align: center;
           overflow: hidden;
           white-space: nowrap;
           text-overflow: ellipsis;
           padding: 0 .2rem;
       }
       li:nth-child(6) {
           width: 4rem;
       }
       padding: 0.2rem 0;
   }
   .ys {
       padding: 0;
       li {
           background: #f6f6f6;
           padding: 0.2rem;
       }
       li:nth-child(1) {background: #ffffff;}
   }
   .box {
       display: flex;
       justify-content: start;
       align-items: flex-start;
       padding:.2rem 0;
       h4 {
           width: 1.5rem;
           padding: 0 .2rem;
       }
       .xz {
           width: 8rem;
           background: #f6f6f6;
           padding: 0.2rem 0;
           padding-left: .2rem;
           //height: 155px;
       }
       .dx {
           display: flex;
           flex-wrap: wrap;
           align-items: center;
           .xxbos {
               padding: .15rem 0;
           }
           .bnt {margin-left: .1rem}
       }
       .yz {
           width: 11.6rem;
           padding: 0;
           background: #ffffff;
       }
       //.h2 {height: 2rem;}
       .bnt {
          max-width: 10em;
       }
       .icd {
         background: #f6f6f6;
         height: 155px;
         padding: 0.2rem;
         margin-left: .1rem;
         .sou {
            display: flex;
            align-items: center;
            .srk {
              width: 13vw;
              margin-left: .2rem;
              margin-right: .1rem;
            }
            i{font-size: 18px;cursor: pointer;}
          }
         .icd-nr {
           height: 110px;
           margin-top: 1vh;
           overflow-y: auto;
           text-align: left;
           li {
             padding: 5px 0;
             .icdnrs {
               width: 3.7rem;
               overflow: hidden;
               text-overflow: ellipsis;
               white-space: nowrap;
               cursor: pointer;
             }
           }
           li:hover{background: #e5e3e3;}
         }
       }
   }
   .hx {
       width: 100%;
       //height: 5px;
       background: #bbbaba;
       margin: .15rem 0;
   }
   .srr {
     width: 140px;
     margin-left: .4rem;
   }
   .jycl {
     padding-bottom: 30px;
     .ywlist {
       display: flex;
       justify-content: start;
       align-items: start;
       width: 100%;
       .ywlist-title {
         //width: ;
         padding-top: .1rem;
         display: flex;
         p {
           width: 4em;
           font-weight: bold;
           font-size: 15px;
           margin-bottom: .1rem;
         }
         ul {
           //padding-top: 25px;
           li {
             display: flex;
             //align-items: flex-start;
             align-items: center;
             padding-bottom: 10px;
             .srr {
               width: 20em;
               margin-right: 20px;
             }
           }
           li:nth-child(1) {
             padding-left: 22px;
             padding-bottom: 5px;
           }
         }
       }
     }
     //ul {
     //  width: 90%;
     //  display: flex;
     //  justify-content: start;
     //  padding-bottom: .1rem;
     //  li:nth-child(1) {width: 45%;}
     //  li:nth-child(2) {width: 15%;}
     //  li:nth-child(3) {width: 15%;}
     //  li:nth-child(4) {width: 10%;}
     //}
   }
   .icdlistss {
     cursor: pointer;
     max-height: 400px;
     overflow-y: auto;
     li {
       padding: 8px 0;
       &:hover{background: #e3e3e3;}
     }
   }
   .yyxq {
     display: flex !important;
     //flex-wrap: ;
     justify-content: center;
     li {
       padding-left: 15px;
       padding-bottom: 10px;
       p {
         padding-bottom: 5px;
       }
     }
   }
</style>
